import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import HeaderDate from './HeaderDate'
import Logo from './Logo'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import { useContext } from 'react'
import { SettingsContext } from '../context/settings'

const UserDropdown = dynamic(() => import('./UserDropdown'))

function Header() {
  const router = useRouter()
  const { slug } = router.query

  const settings = useContext(SettingsContext)
  const menuOptions = settings?.menus.main || []

  return (
    <header className="sticky top-0 left-0 z-20 w-full bg-white lg:block">
      <div className="header-top">
        <MobileMenu options={menuOptions} currentPath={router.asPath} />
        <HeaderDate className="hidden xl:block" />
        <Logo isHeader />
        <div className="user-dropdown">
          {/* @ts-ignore */}
          <UserDropdown />
        </div>
      </div>
      <Menu options={menuOptions} currentPath={router.asPath} />
      <style jsx>{`
        .header-top {
          @apply relative mx-auto flex h-[60px] max-w-[1440px] items-center justify-between px-4 md:h-[100px] xl:px-8;
        }
        .user-dropdown {
          @apply hidden flex-row-reverse items-center gap-2 lg:flex lg:flex-1;
        }
      `}</style>
    </header>
  )
}

export default Header
